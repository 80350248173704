import React, { useState } from 'react';

import { Button } from '../../components/Button';
import Input from '../../components/Input';

import logoImg from '../../assets/CEDAPP-CRM.png';
import { Checkbox } from '../../components/Checkbox';
import InputMask from '../../components/InputMask';
import { useAuth } from '../../hooks/auth';
import {
  apiHnsn,
  apiLaureano,
  apiLaureanoSoul,
  authApi,
} from '../../services/api';

import { Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useLoader } from '../../hooks/loading';
import { VerificationCodeModal } from './Modal';
import { Container, Content, Logo, Title } from './styles';

interface LoginObjectProps {
  login: string;
  password: string;
  isMedico: boolean;
}
export function SignIn() {
  const history = useHistory();
  const [loginObject, setLoginObject] = useState<LoginObjectProps>({
    isMedico: true,
    login: '',
    password: '',
  });
  const [verificationCode, setVerificationCode] = useState<boolean>(false);
  const { loadStorageData } = useAuth();

  const { showLoader, hideLoader } = useLoader();

  async function handleSignIn() {
    if (!loginObject.login || !loginObject.password) {
      alert('Preencha os dados para fazer login!');
      return;
    }

    showLoader();

    try {
      const response = await apiLaureano.post('/session', {
        login: loginObject.login,
        password: loginObject.isMedico
          ? loginObject.password.replace(/\D/gim, '')
          : loginObject.password,
        isMedico: loginObject.isMedico,
      });

      const { token, user } = response.data;

      localStorage.setItem('@cedapp:tokenLaureano', token);
      localStorage.setItem('@cedapp:userLaureano', JSON.stringify(user));

      apiLaureano.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } catch (error) {}

    try {
      const responseHnsn = await apiHnsn.post('/session', {
        login: loginObject.login,
        password: loginObject.isMedico
          ? loginObject.password.replace(/\D/gim, '')
          : loginObject.password,
        isMedico: loginObject.isMedico,
      });
      const { token, user } = responseHnsn.data;

      localStorage.setItem('@cedapp:tokenHnsn', token);
      localStorage.setItem('@cedapp:userHnsn', JSON.stringify(user));

      apiHnsn.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } catch (error) {}

    try {
      const responseLaureanoSoul = await apiLaureanoSoul.post('/session', {
        login: loginObject.login,
        password: loginObject.isMedico
          ? loginObject.password.replace(/\D/gim, '')
          : loginObject.password,
        isMedico: loginObject.isMedico,
      });
      const { token, user } = responseLaureanoSoul.data;

      localStorage.setItem('@cedapp:tokenLaureanoSoul', token);
      localStorage.setItem('@cedapp:userLaureanoSoul', JSON.stringify(user));

      apiLaureanoSoul.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${token}`;
    } catch (error) {}

    hideLoader();

    await loadStorageData();

    history.push('/home');
  }

  async function preSignIn() {
    if (loginObject.login === '0000') {
      handleSignIn();
      return;
    }
    try {
      const { data } = await authApi.post('/auth-tokens', {
        login: loginObject.login.replace(/\./g, '').replace(/-/g, ''),
        password: loginObject.password,
        isDoctor: loginObject.isMedico,
      });
      setVerificationCode(true);
    } catch (error) {
      alert('Usuário ou senha inválidos');
    }
  }

  async function handleVerifyCode(code: string) {
    if (code === '') {
      return alert('Código de verificação inválido');
    }
    try {
      await authApi.post('/validate-auth-tokens', {
        login: loginObject.login.replace(/\./g, '').replace(/-/g, ''),
        token: code,
      });

      setVerificationCode(false);
      handleSignIn();
    } catch (error) {
      alert('Código de verificação inválido');
    }
  }

  return (
    <Container>
      <Logo src={logoImg} alt="CEDAPP" />

      <div>
        <Title>Faça seu login</Title>
      </div>

      <Content>
        <div>
          <Checkbox
            onClick={(checked) =>
              setLoginObject((prevState) => {
                return { ...prevState, isMedico: checked };
              })
            }
            label={'Acesso médico'}
            checked={loginObject.isMedico}
          />
          <Checkbox
            onClick={(checked) =>
              setLoginObject((prevState) => {
                return { ...prevState, isMedico: !checked };
              })
            }
            label="Acesso paciente"
            checked={!loginObject.isMedico}
          />
        </div>

        {loginObject.isMedico ? (
          <>
            <Input
              onChange={(e) => {
                e.persist(); // Preserve o evento sintético
                setLoginObject((prevState) => ({
                  ...prevState,
                  login: e.target.value,
                }));
              }}
              placeholder="CRM"
              value={loginObject?.login}
            />
            <InputMask
              onChange={(e) => {
                e.persist(); // Preserve o evento sintético
                setLoginObject((prevState) => ({
                  ...prevState,
                  password: e.target.value,
                }));
              }}
              value={loginObject?.password}
              placeholder="CPF"
              mask="999.999.999-99"
            />
          </>
        ) : (
          <>
            <InputMask
              onChange={(e) => {
                e.persist(); // Preserve o evento sintético
                setLoginObject((prevState) => ({
                  ...prevState,
                  login: e.target.value,
                }));
              }}
              value={loginObject?.login}
              placeholder="CPF"
              mask="999.999.999-99"
            />

            <InputMask
              onChange={(e) => {
                e.persist(); // Preserve o evento sintético
                setLoginObject((prevState) => ({
                  ...prevState,
                  password: e.target.value,
                }));
              }}
              value={loginObject?.password}
              placeholder="Data de nascimento"
              mask="99/99/9999"
            />
          </>
        )}

        <Dialog
          open={verificationCode}
          onClose={() => setVerificationCode(false)}
          PaperProps={{
            style: {
              background: 'transparent',
            },
          }}
        >
          <VerificationCodeModal
            handleCloseModal={() => setVerificationCode(false)}
            handleSendVerificationCode={(code) => handleVerifyCode(code)}
          />
        </Dialog>
        <Button onClick={preSignIn}>Entrar</Button>
      </Content>
    </Container>
  );
}
