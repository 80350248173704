import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Dashboard } from '../pages/Dashboard';
import { SignIn } from '../pages/SignIn';
import LaudoView from '../pages/laudo';

export function PrivateRoutes() {
  const tokenLaureano = localStorage.getItem('@cedapp:tokenLaureano');
  const tokenHnsn = localStorage.getItem('@cedapp:tokenHnsn');
  const tokenLaureanoSoul = localStorage.getItem('@cedapp:tokenLaureanoSoul');

  if (!tokenLaureano || !tokenHnsn || !tokenLaureanoSoul) {
    return <Redirect to="/login" />;
  }

  return (
    <Switch>
      <Route path="/" component={Dashboard} />
      <Route path="/home" exact component={Dashboard} />
      <Route path="/laudo/:cdLaudo" exact component={LaudoView} />
    </Switch>
  );
}
export function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={SignIn} />
        <Route path="/*" component={PrivateRoutes} />
      </Switch>
    </BrowserRouter>
  );
}
