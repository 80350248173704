import axios from 'axios';

const apiLaureano = axios.create({
  baseURL: 'https://cedap.hlaureano.org.br:9090',
});

const apiLaureanoSoul = axios.create({
  baseURL: 'https://cedap.hlaureano.org.br:9191',
});

const apiHnsn = axios.create({
  baseURL: 'https://vpn.hnsn.com.br:8284',
});

const authApi = axios.create({
  //baseURL: 'http://144.126.128.160:50001/',
  baseURL: 'https://dataintegra4342.integrator.host/api/auth/cedapp',
  //baseURL: 'http://localhost:3333/',
});

export { apiHnsn, apiLaureano, apiLaureanoSoul, authApi };
