import styled from "styled-components";

export const ModalContainer = styled.div`
    width: 500px;
    min-height: 200px;
    border: 1px solid var(--primary);
    background: var(--background);
    color: var(--primary);
    border-radius: 10px;
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
`

export const CloseButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button{
        background: none;
        border: none;
    }
`

export const MessageContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    span{
        border: 1px solid  var(--primary);
        border-radius: 5px;
        min-width: 35px;
        min-height: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    label{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;        
    h4{
        font-size: 1rem;
        font-weight: 700;
        color: black;
    }
    p{
        font-size: 0.75rem;
        font-weight: 400;
        color: black;

    }
    }
`

export const ButtonsContainer = styled.div`
display: flex;
gap: 1rem;
button:first-child{
    background-color: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
}
`