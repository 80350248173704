import { DeviceMobile, X } from 'phosphor-react';
import React, { useState } from 'react';
import { Button } from '../../../components/Button';
import Input from '../../../components/Input';
import * as S from './styles';
interface VerificationCodeModalProps {
    handleCloseModal: () => void;
    handleSendVerificationCode: (code: string) => void;
}
export function VerificationCodeModal({ handleCloseModal, handleSendVerificationCode }: VerificationCodeModalProps) {
    const [code, setCode] = useState<string>('');
    return (
        <S.ModalContainer>
            <S.CloseButton>
                <button onClick={handleCloseModal}>
                    <X size={24} color="#0b0909" />
                </button>
            </S.CloseButton>
            <S.MessageContainer>
                <span>
                    <DeviceMobile size={30} color="#007467" />
                </span>
                <label>
                    <h4>Enviamos um código de verificação</h4>
                    <p>Verifique seu telefone e informe-o abaixo</p>
                </label>
            </S.MessageContainer>
            <Input
                placeholder='Código de verificação'
                onChange={(e) => setCode(e.target.value)}
                value={code}
            />
            <S.ButtonsContainer>
                <Button onClick={() => {
                    handleCloseModal();
                }}
                >Cancelar</Button>
                <Button
                    onClick={() => {
                        handleSendVerificationCode(code);
                    }}
                >Verificar</Button>
            </S.ButtonsContainer>
        </S.ModalContainer>
    )
}